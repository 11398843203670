<template>
<div>
    <v-app-bar
        flat
        color="#ffffff"
    >
        <v-img
            src="@/assets/brevity-logo-dark.svg"
            max-width="120"
        >
        </v-img>
    </v-app-bar>
    <v-container  fluid class="mt-10">
        <v-row dense>
            <v-col lg="6" offset-lg="3">
                <v-card class="text-center pa-3">
                    <v-card-title class="text-uppercase">Create An Account</v-card-title>
                    <!--Account registration form -->
                    <!--New Customer form -->
                <v-form ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    v-model="displayName"
                                    required
                                    label="Full Name"
                                    append-icon="mdi-account"
                                    filled
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    v-model="user_email"
                                    required
                                    label="Email"
                                    append-icon="mdi-email"
                                    filled
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    v-model="user_password"
                                    required
                                    label="Password"
                                    append-icon="mdi-lock"
                                    filled
                                    color="#3d2cdd"
                                    type="password"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-btn
                        v-on:click="registerUser"
                        color="#33cc99"
                        dark
                        block
                        x-large
                    >
                        Create Your Account
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <router-link
                        to="/login"
                        class="text-decoration-none"
                    >
                        Already have an account? Login
                    </router-link>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
 import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js 
import firebase from 'firebase'
export default {
    name: 'Register',
    
    data: function(){
        return {
            uid: '',
            displayName: '',
            user_email: '',
            user_password: '',
            roles: [''],
        }
    },
    methods: {
        //Adds a new user to database
        registerUser: function (e) {
            firebase.auth().createUserWithEmailAndPassword(this.user_email, this.user_password)
            .then(cred => {
                //Send email verification link
                const thisUser = firebase.auth().currentUser
                thisUser.sendEmailVerification();

                db.collection('users').doc(cred.user.uid).set({
                    //Populated by the v-model values in the form
                    //Sets the user_id == to the document id in database
                    uid: cred.user.uid,
                    displayName: this.displayName,
                    user_email: this.user_email,
                    //New users can view products and customers
                    roles: [''],
                    user_created: new Date().toISOString().slice(0, 10),
                    user_timestamp: firebase.firestore.FieldValue.serverTimestamp() 
                })
                //Go to dashboard
                this.$router.push('/welcome')
            },
            err => {
                alert(err.message)
            })
            e.preventDefault()
        },
    }
}
</script>