<template>
  <v-container>
    <!--Header-->
    <v-row class="mb-8">
      <v-col lg="12">
        <v-card-title>
          <h2>Dashboard</h2>
        </v-card-title>
      </v-col>
    </v-row>

    <!--Card skeleton loader-->
    <div v-if="firstLoad">
      <v-row>
        <v-col>
          <v-skeleton-loader
            :loading="loading_user"
            type="card-heading"
            max-width="350"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <!--End of skelton loader-->
    <!--Sales Orders-->
    <div
      v-if="!firstLoad"
      v-show="this.user_roles_list.flat().includes('sales_read')"
    >
      <v-row>
        <v-col>
          <v-card-title>
            <h4>Sales Orders</h4>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col
          md="3"
          lg="3"
          v-for="(item, a) in dashboard_items_orders"
          :key="a"
        >
          <v-sheet elevation="2">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle class="font-weight-bold">
                  <v-icon left x-small :color="item.color"> mdi-circle</v-icon>
                  {{ item.name }}
                </v-list-item-subtitle>
                <v-list-item-title class="headline mb-1 font-weight-bold">
                  {{ countDashboardItems_Orders(item.item_order_status) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <!--Card skeleton loader-->
    <div v-if="firstLoad">
      <v-row>
        <v-col>
          <v-skeleton-loader
            :loading="loading_user"
            type="card-heading"
            max-width="350"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <!--End of skelton loader-->
    <div
      v-if="!firstLoad"
      v-show="this.user_roles_list.flat().includes('work_orders_read')"
    >
      <!--Work Orders-->
      <v-row>
        <v-col>
          <v-card-title>
            <h4>Work Orders</h4>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col
          md="3"
          lg="3"
          v-for="(item, b) in dashboard_items_work_orders"
          :key="b"
        >
          <v-sheet elevation="2">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle class="font-weight-bold">
                  <v-icon left x-small :color="item.color"> mdi-circle</v-icon>
                  {{ item.name }}
                </v-list-item-subtitle>
                <v-list-item-title class="headline mb-1 font-weight-bold">
                  {{
                    countDashboardItems_WorkOrders(item.item_work_order_status)
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
        <!--Updated Sales Orders-->
        <v-col md="3" lg="3">
          <v-sheet elevation="2">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle class="font-weight-bold">
                  <v-icon left x-small color="#f9d493"> mdi-circle</v-icon>
                  Updated Sales Orders
                </v-list-item-subtitle>
                <v-list-item-title class="headline mb-1 font-weight-bold">
                  {{ updatedSalesCount() }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
        <!--Cancelled Sales Orders-->
        <v-col md="3" lg="3">
          <v-sheet elevation="2">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle clas="font-weight-bold">
                  <v-icon left x-small color="#ce2458"> mdi-circle</v-icon>
                  Cancelled Sales Orders
                </v-list-item-subtitle>
                <v-list-item-title class="headline mb-1 font-weight-bold">
                  {{ cancelledSalesCount() }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <!--Card skeleton loader-->
    <div v-if="firstLoad">
      <v-row>
        <v-col>
          <v-skeleton-loader
            :loading="loading_user"
            type="card-heading"
            max-width="350"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="88"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <!--End of skelton loader-->
    <!--Deliveries-->
    <div
      v-if="!firstLoad"
      v-show="this.user_roles_list.flat().includes('deliveries_read')"
    >
      <v-row>
        <v-col>
          <v-card-title>
            <h4>Deliveries</h4>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col
          md="3"
          lg="3"
          v-for="(item, c) in dashboard_items_deliveries"
          :key="c"
        >
          <v-sheet elevation="2">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon left x-small :color="item.color"> mdi-circle</v-icon>
                  {{ item.name }}
                </v-list-item-subtitle>
                <v-list-item-title class="headline mb-1 font-weight-bold">
                  {{
                    countDashboardItems_Deliveries(item.item_delivery_status)
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import db from "../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from "../globalActions/mixin_UserRoles";

export default {
  name: "Home",
  mixins: [mixin_UserRoles],
  data() {
    return {
      //Empty object arrays
      sales_orders: [],
      work_orders: [],
      delivery_notes: [],
      dashboard_items_orders: [
        { name: "Quotes", item_order_status: "Quote", color: "#c5d0ed" },
        {
          name: "Confirmed Orders",
          item_order_status: "Confirmed",
          color: "#88e2b7",
        },
      ],
      dashboard_items_work_orders: [
        {
          name: "Work Order Created",
          item_work_order_status: "Work Order Created",
          color: "#c5d0ed",
        },
        {
          name: "In Progress",
          item_work_order_status: "In Progress",
          color: "#88e2b7",
        },
      ],
      dashboard_items_deliveries: [
        {
          name: "Delivery Note Created",
          item_delivery_status: "Delivery Note Created",
          color: "#c5d0ed",
        },
        {
          name: "Ready For Dispatch",
          item_delivery_status: "Ready For Dispatch",
          color: "#f9d493",
        },
        {
          name: "Dispatched",
          item_delivery_status: "Dispatched",
          color: "#88e2b7",
        },
      ],
    };
  },
  created() {
    db
      .collection("sales_orders")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = {
            order_status: doc.data().order_status,
          };
          //Pushes the customers objects to the empty customers array above
          this.sales_orders.push(data);
        });
      }),
      db
        .collection("work_orders")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              work_order_production_status:
                doc.data().work_order_production_status,
              sales_order_updated: doc.data().sales_order_updated,
              sales_order_cancelled: doc.data().sales_order_cancelled,
            };
            //Pushes the customers objects to the empty customers array above
            this.work_orders.push(data);
          });
        }),
      db
        .collection("delivery_notes")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              delivery_status: doc.data().delivery_status,
            };
            //Pushes the customers objects to the empty customers array above
            this.delivery_notes.push(data);
          });
        });
  },

  methods: {
    //  Calculates total count of sales order for each order status
    //  and displays them on dashbaord summary pannel
    countDashboardItems_Orders(item_order_status) {
      const statusCount = this.sales_orders.filter(
        (sales_orders) => sales_orders.order_status === item_order_status
      );
      return statusCount.length;
    },
    countDashboardItems_WorkOrders(item_work_order_status) {
      const statusCount = this.work_orders.filter(
        (work_orders) =>
          work_orders.work_order_production_status === item_work_order_status
      );
      return statusCount.length;
    },
    countDashboardItems_Deliveries(item_delivery_status) {
      const statusCount = this.delivery_notes.filter(
        (delivery_notes) =>
          delivery_notes.delivery_status === item_delivery_status
      );
      return statusCount.length;
    },
    updatedSalesCount() {
      const statusCount = this.work_orders.filter(
        (work_orders) => work_orders.sales_order_updated
      );
      return statusCount.length;
    },
    cancelledSalesCount() {
      const cancelCount = this.work_orders.filter(
        (work_orders) => work_orders.sales_order_cancelled
      );
      return cancelCount.length;
    },
  },
};
</script>
