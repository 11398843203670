<template>
  <v-container>
    <v-row class="mb-8">
      <v-col lg="12">
        <v-card-title>
          <v-btn to="/admin" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h2>Edit User Roles</h2>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="#33cc99"
            @click="saveUserRoles"
            :loading="loading_update"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save User Roles
          </v-btn>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet elevation="2">
          <v-card-title>
            {{ displayName }}
          </v-card-title>
          <v-card-subtitle>
            {{ user_email }}
          </v-card-subtitle>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col>
        <v-sheet elevation="2">
          <v-simple-table>
            <thead>
              <tr>
                <!--Table Headers-->
                <th
                  class="text-left"
                  v-for="header in role_headers"
                  :key="header.name"
                >
                  <h3>{{ header.name }}</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <!--------------------------------------------->
              <!--USER ROLE TABLE-->
              <tr
                v-for="role_module in role_table.role_modules"
                :key="role_module.name"
              >
                <td>{{ role_module.name }}</td>
                <td
                  v-for="checkbox in role_module.checkboxes"
                  :key="checkbox.value"
                >
                  <!--View customer-->
                  <v-checkbox
                    v-model="roles"
                    :value="checkbox.value"
                    color="#33cc99"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "../../../components/firebaseInit";
import { showSnackbar } from "../../../globalActions/index";

export default {
  name: "EditUser",
  data() {
    return {
      uid: null,
      user_email: null,
      displayName: null,
      loading_update: false,
      //user roles
      roles: [""],
      role_headers: [
        { name: "Module Name" },
        { name: "Apply All" },
        { name: "View" },
        { name: "Add / Edit" },
        { name: "Delete" },
        { name: "Reports" },
      ],
      role_table: {
        role_modules: [
          {
            name: "Customers",
            checkboxes: [
              { value: "customers_all", type: "roles" },
              { value: "customers_read", type: "roles" },
              { value: "customers_write", type: "roles" },
              { value: "customers_delete", type: "roles" },
            ],
          },
          {
            name: "CRM",
            checkboxes: [
              { value: "crm_all", type: "roles" },
              { value: "crm_read", type: "roles" },
              { value: "crm_write", type: "roles" },
            ],
          },
          {
            name: "Suppliers",
            checkboxes: [
              { value: "suppliers_all", type: "roles" },
              { value: "suppliers_read", type: "roles" },
              { value: "suppliers_write", type: "roles" },
              { value: "suppliers_delete", type: "roles" },
            ],
          },
          {
            name: "Sales Orders",
            checkboxes: [
              { value: "sales_all" },
              { value: "sales_read" },
              { value: "sales_write" },
              { value: "sales_delete" },
              { value: "sales_reporting" },
            ],
          },
          {
            name: "Work Orders",
            checkboxes: [
              { value: "work_orders_all" },
              { value: "work_orders_read" },
              { value: "work_orders_write" },
              { value: "work_orders_delete" },
            ],
          },
          {
            name: "Banking",
            checkboxes: [
              { value: "banking_all" },
              { value: "banking_read" },
              { value: "banking_write" },
              { value: "banking_delete" },
            ],
          },
          {
            name: "Accounting",
            checkboxes: [
              { value: "accounting_all" },
              { value: "accounting_read" },
              { value: "accounting_write" },
              { value: "accounting_delete" },
            ],
          },
          {
            name: "Deliveries",
            checkboxes: [
              { value: "deliveries_all" },
              { value: "deliveries_read" },
              { value: "deliveries_write" },
              { value: "deliveries_delete" },
            ],
          },
          {
            name: "Products",
            checkboxes: [
              { value: "products_all" },
              { value: "products_read" },
              { value: "products_write" },
              { value: "products_delete" },
            ],
          },
          {
            name: "Ecommerce",
            checkboxes: [
              { value: "ecommerce_all", type: "roles" },
              { value: "ecommerce_read", type: "roles" },
              { value: "ecommerce_write", type: "roles" },
              { value: "ecommerce_delete", type: "roles" },
            ],
          },
          {
            name: "Employees",
            checkboxes: [
              { value: "employees_all" },
              { value: "employees_read" },
              { value: "employees_write" },
              { value: "employees_delete" },
            ],
          },
          {
            name: "Admin",
            checkboxes: [{ value: "admin_all" }, { value: "super_admin" }],
          },
        ],
      },

      allEmployeesSelected: false,
    };
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("users")
      .where("uid", "==", to.params.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.uid = doc.data().uid;
            vm.user_email = doc.data().user_email;
            vm.displayName = doc.data().displayName;
            vm.roles = doc.data().roles;
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    //Maps database data to template relative to the customer selected in this route
    fetchData() {
      db.collection("users")
        .where("uid", "==", this.$route.params.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.uid = doc.data().uid;
            this.user_email = doc.data().user_email;
            this.displayName = doc.data().displayName;
            this.roles = doc.data().roles;
          });
        });
    },
    selectAllEmployeeRoles() {},
    //Save user roles to array in the database
    saveUserRoles() {
      this.loading_update = true;
      db.collection("users")
        .where("uid", "==", this.$route.params.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .update({
                roles: this.roles,
              })
              //Reroute to employee dashboard page after database has been updated
              .then(() => {
                this.loading_update = false;
                showSnackbar("User roles updated successfully");
                this.$router.push("/admin");
              });
          });
        });
    },
  },
};
</script>
