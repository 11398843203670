<template>
  <p>No Permission</p>
</template>
<script>
export default {
  name: "NoPermission",
  data() {
    return {};
  },
};
</script>