<template>
    <AdminTable />
    
</template>

<script>
// @ is an alias to /src
import AdminTable from '../components/AdminTable';

export default {
  name: 'Admin',
  components: {
    AdminTable,
  },
}
</script>