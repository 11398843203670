<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col class="mb-8">
          <v-card-title>
            <h2>User List</h2>
          </v-card-title>
        </v-col>
      </v-row>
      <!--Table skeleton loader-->
      <v-row v-if="firstLoad">
        <v-col>
          <v-sheet elevation="4">
            <v-skeleton-loader
              :loading="loading_user"
              type="table"
              max-height="500"
            >
            </v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <!--End of skeleton loader-->
      <v-row
        v-if="!firstLoad"
        v-show="this.user_roles_list.flat().includes('super_admin')"
      >
        <v-col>
          <v-sheet elevation="4">
            <!--Table-->
            <v-data-table
              :headers="adminTableHeaders"
              :items="users"
              :search="search"
              :items-per-page="100"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-spacer />
                    <!--Search Bar-->
                    <v-col lg="4" md="4">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        rounded
                        dense
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.uid="{ item }">
                <v-btn
                  :to="{ name: 'edit-user', params: { uid: item.uid } }"
                  color="#3d2cdd"
                  icon
                  small
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import store from "../store/store";
import { mapState } from "vuex";
export default {
  name: "AdminTable",
  mixins: [mixin_UserRoles],
  data() {
    return {
      search: "",
      //Users are pushed to this array and added to the data table
      uid: null,
      users: [],
      loading: true,
      firstLoad: true,
    };
  },
  created() {
    db.collection("users")
      .orderBy("displayName")
      .get()
      .then((querySnapshot) => {
        this.users = [];
        this.firstLoad = true;
        this.loading = true;
        querySnapshot.forEach((doc) => {
          console.log(doc.id);
          const data = {
            id: doc.id,
            uid: doc.data().uid,
            displayName: doc.data().displayName,
            user_email: doc.data().user_email,
            user_cell: doc.data().user_cell,
            roles: doc.data().roles.length,
          };
          //Pushes the customers objects to the empty users array above
          this.users.push(data);
          this.firstLoad = false;
          this.loading = false;
        });
      });
  },
  methods: {},
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    adminTableHeaders: (state) => state.adminTableHeaders,
  }),
};
</script>

<style scoped>
.margin-left-20px {
  margin-left: 20px;
}
tr.quote-tr-style::before {
  background-color: #b0bcdf !important;
}
.style-2 {
  background-color: rgb(114, 114, 67);
}
</style>

<style scoped>
.white-text {
  color: #fff;
}
</style>