<template>
    <v-container>
        <v-row class="mt-10">
            <v-col class="text-center pa-3">
                    <v-img
                        src="@/assets/brevity-business-welcome-13.svg"
                        max-width="700"
                        class="center-img"
                    >
                    </v-img>
                    <h1>Welcome To Brevity</h1>
                    <p class="mt-3 subtitle-1">
                        The cloud platform that makes managing your entire<br>
                        buisness quick and easy
                    </p>
                    <v-btn
                        large
                        class="mt-3"
                        dark
                        color="#33cc99"
                        to='/'
                    >Get Started</v-btn>
                    <p class="mt-5 caption">Not your first time here? <router-link to="/">Skip the tutorial</router-link></p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Welcome'
}
</script>

<style scoped>
    .center-img{
        margin: 0 auto;
    }
</style>