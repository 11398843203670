<template>
<div>
    <v-app-bar
        flat
        color="#ffffff"
    >
        <v-img
            src="@/assets/brevity-logo-dark.svg"
            max-width="120"
        >
        </v-img>
    </v-app-bar>
    <v-container  fluid class="mt-10">
        <v-row dense>
            <v-col lg="6" offset-lg="3">
                <v-card class="text-center pa-3">
                    <v-card-title class="text-uppercase">Login</v-card-title>
                    
                    <!--Login form -->
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    v-model="user_email"
                                    required
                                    label="Email"
                                    append-icon="mdi-email"
                                    filled
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    v-model="user_password"
                                    required
                                    label="Password"
                                    append-icon="mdi-lock"
                                    filled
                                    color="#3d2cdd"
                                    type="password"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-btn
                        v-on:click="loginUser"
                        color="#33cc99"
                        dark
                        block
                        x-large
                    >
                        Login
                    <v-icon right>mdi-login</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <router-link
                        to="/register"
                        class="text-decoration-none"
                    >
                        Sign up for an account
                    </router-link>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import firebase from 'firebase'
export default {
    name: 'Login',
    data: function(){
        return {
            user_email: '',
            user_password: ''
        }
    },
    methods: {
        loginUser: function (e) {
            firebase.auth().signInWithEmailAndPassword(this.user_email, this.user_password)
            .then(user => {
                console.log(`You are logged in as ${user.user_email}`);
                this.$router.push('/')
            },
            err => {
                alert(err.message)
            })
            e.preventDefault()
        }
    }
}
</script>