import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    //BUTTONS
    primaryCTA, secondaryCTA, addUserBtn,

    //DATA TABLE HEADERS
    adminTableHeaders,

    //FORM HEADERS (ADD)
} from '../data/data'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //BUTTONS
        primaryCTA, secondaryCTA, addUserBtn,

        //DATA TABLE HEADERS
        adminTableHeaders,

        //FORM HEADERS (ADD)
    },
    mutations: {},
    actions: {},
    modules: {},
});