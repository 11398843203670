//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: 'secondary',
    btn_ml_10: 'ml-10',
    btn_view_icon: 'mdi-eye',
}
//Add user button data
const addUserBtn = {
    btn_add_user: 'New User',
    btn_new_user_to: '/new-user',
}

//----------------------------------------------
//DATA TABLE HEADERS
//Admin table
const adminTableHeaders = [
    { text: 'Edit User', value: 'uid' },
    { text: 'Full Name', value: 'displayName' },
    { text: 'Email', value: 'user_email' },
    { text: 'Roles', value: 'roles' }
]

//----------------------------------------------
//FORM HEADERS


//EXPORTED to the Vuex state block
export {
    //BUTTONS
    primaryCTA, secondaryCTA, addUserBtn,

    //DATA TABLE HEADERS
    adminTableHeaders,

    //FORM HEADERS (ADD)

}